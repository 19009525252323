import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import wx from 'weixin-js-sdk';

import { localStorageKeys } from '@/constants';
import {fellowHttpRequest, httpRequest} from '@/utils';

function useWechatConfig() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem(localStorageKeys.token);

  const getToken = async (tenantId, code) => {
    if (localStorage.getItem(localStorageKeys.code) === code) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const res = await fellowHttpRequest.get(`/client/noAuth/login/h5/${tenantId}/${code}`);
      if (res.code === 0) {
        localStorage.setItem(localStorageKeys.code, code);

        sessionStorage.setItem(localStorageKeys.token, res.data.token);
        sessionStorage.setItem(localStorageKeys.bindState, res.data.bindState);
      } else if (res.code === 1) {
        if (res.errorCode === '001' || res.errorCode === '002') {
          navigate('guide');
        }
        throw new Error(res.msg);
      }
    } catch (err) {
      message.error(err?.message || '获取openId失败');
    } finally {
      setLoading(false);
    }
  };

  const wechatConfig = async () => {
    try {
      const res = await fellowHttpRequest.post('/client/noAuth/h5/official/config', {
        requestUrl: window.location.href,
      });

      if (res.code !== 0) {
        throw new Error(res.msg);
      }

      wx.config({
        debug: false,
        appId: res.data?.appid,
        nonceStr: res.data?.noncestr,
        signature: res.data?.signature,
        timestamp: res.data?.timestamp,
        jsApiList: ['closeWindow', 'updateAppMessageShareData'],
        openTagList: ['wx-open-launch-weapp'],
      });
      wx.ready(() => {
        wx.updateAppMessageShareData({
          title: '云彩人资科研-发薪', // 分享标题
          desc: '云彩人资科研专注于为劳务行业提供标准化SaaS服务，致力于打造好用易用的用工系统', // 分享描述
          link: process.env.REACT_APP_DOMAIN, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: process.env.REACT_APP_LOGO_URL, // 分享图标
          success() {
            // 设置成功
          },
        });
      });
    } catch (err) {
      message.error(err?.message || '微信配置失败');
    }
  };

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search);
      const code = urlSearchParams.get('code');
      const tenantId = urlSearchParams.get('state');
      sessionStorage.setItem(localStorageKeys.tenantId, tenantId);
      const localCode = localStorage.getItem(localStorageKeys.code);
      const equallyCode = localCode === code;
      if (!token) {
        if (!tenantId) {
          navigate('guide');
        }
        if (!code) {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx27e76ff8d52078ef&redirect_uri=${process.env.REACT_APP_DOMAIN}&response_type=code&scope=snsapi_base&state=${tenantId}#wechat_redirect`;
        }
        if (equallyCode) {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx27e76ff8d52078ef&redirect_uri=${process.env.REACT_APP_DOMAIN}&response_type=code&scope=snsapi_base&state=${tenantId}#wechat_redirect`;
        } else {
          getToken(tenantId, code);
        }
      } else {
        setLoading(false);
      }
    } else {
      if (!token) {
        navigate('guide');
      }
      // window.location.href = `${process.env.REACT_APP_DOMAIN}/guide`;
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    wechatConfig();
  }, []);

  return { loading };
}

export default useWechatConfig;
