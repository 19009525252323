import axios from 'axios';

import { localStorageKeys } from '@/constants';

// 公共的处理未授权逻辑
const handleUnauthorized = () => {
  sessionStorage.setItem(localStorageKeys.token, '');
  const tenantId = sessionStorage.getItem(localStorageKeys.tenantId);
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx27e76ff8d52078ef&redirect_uri=${process.env.REACT_APP_DOMAIN}&response_type=code&scope=snsapi_base&state=${tenantId}#wechat_redirect`;
};

// 创建 axios 实例的通用方法
const createAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    timeout: 30000,
    headers: {},
  });

  // 添加请求拦截器
  instance.interceptors.request.use((config) => {
    config.headers['X-User-Token'] = sessionStorage.getItem(localStorageKeys.token);
    return config;
  }, (error) => Promise.reject(error));

  // 添加响应拦截器
  instance.interceptors.response.use((response) => {
    if (response?.data?.code === 10) {
      handleUnauthorized();
    }
    return response.data;
  }, (error) => {
    if (error.response.status === 401) {
      handleUnauthorized();
    }
    return Promise.reject(error);
  });

  return instance;
};

// 创建两个不同的 axios 实例
const instance = createAxiosInstance(process.env.REACT_APP_API_ENDPOINT);
const contractInstance = createAxiosInstance(process.env.REACT_APP_API_ENDPOINT_CONTRACT);
const fellowInstance = createAxiosInstance(process.env.REACT_APP_API_ENDPOINT_FELLOW);

// 默认导出主 instance，兼容历史代码
export default instance;

// 命名导出 contractInstance,fellowInstance
export { instance, contractInstance, fellowInstance };
