import React, {useEffect, useRef, useState} from 'react';
import {message, Spin, Upload, Modal} from 'antd';
import * as Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import FileMD5 from '@utils/md5';
import {localStorageKeys} from '@/constants';

// const actionUrl = "https://fgateway.fat.ycrzky.com/tezzolo-finance-manager/common/file/upload";
const actionUrl = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_UPLOAD_URL;

const UploadFile = ({fileType, fileLists, fileChange, beforeUpload, multiple, limit=-1}) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [files, setFiles] = useState([]);
    const filesRef = useRef(files);
    const pendingUploadsRef = useRef(0);
    
    // 统一样式配置
    const cardSize = 104; // 设置卡片的大小（宽高一致）

    useEffect(() => {
        if (fileLists) {
            setFiles(fileLists);
        }
    }, [fileLists]);

    useEffect(() => {
        filesRef.current = files;
    }, [files]);

    // 解析前端file的信息，给到后端
    const parseFiles = (targetFiles) => targetFiles.map((val) => {
        const newFileObj = {};
        newFileObj.fileKey = val.fileKey;
        newFileObj.url = val.url;
        newFileObj.md5 = val.md5;
        newFileObj.name = val.name;
        return newFileObj;
    });

    const checkPicture = (fileName) => /\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName);

    const handlePreview = async (file) => {
        if (file.fileKey && checkPicture(file.fileKey)) {
            setPreviewImage(file.url || file.preview);
            setPreviewVisible(true);
        } else {
            window.open(file.url, '_blank');
        }
    };

    const handleCancel = () => setPreviewVisible(false);
    
    // 处理删除操作
    const onRemove = (file) => {
        Modal.confirm({
            title: '确认删除',
            content: '确定要删除这个文件吗？',
            okText: '删除',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                const newFileList = [...(files.filter((val) => !(val.url === file.url)))];
                setFiles(newFileList);
                fileChange(parseFiles(newFileList));
                message.success('文件已删除');
            },
        });
        return false;
    };

    // 自定义上传操作
    const customRequest = async ({file}) => {
        // 使用引用来获取当前的文件数量，确保在批量上传时也能获取实时值
        if (limit > -1 && filesRef.current.length + pendingUploadsRef.current >= limit) {
            message.error('已超出可上传数量限制');
            return;
        }
        
        // 增加待上传文件计数
        pendingUploadsRef.current += 1;
        
        const newFile = await handleUpload(file);
        
        // 减少待上传文件计数
        pendingUploadsRef.current -= 1;
        
        if (newFile) {
            const newFiles = [...filesRef.current, newFile];
            setFiles(newFiles);
            fileChange(parseFiles(newFiles))
        }
    };

    const handleUpload = async (file) => {
        let result = null;
        if (!beforeUpload()) {
            return null;
        }
        try {
            setLoading(true);
            let md5 = '';
            await FileMD5(file).then((e) => {
                md5 = e;
            }).catch((err) => {
                message.error('计算md5失败');
            });
            const isRepeat = files.some((val) => val.md5 === md5);
            if (isRepeat) {
                message.warning('该文件已上传');
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            await axios({
                method: 'post',
                url: actionUrl,
                data: formData,
                timeout: 30000,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-User-Token': sessionStorage.getItem(localStorageKeys.token) || '',
                },
            }).then((res) => {
                if (res.data && res.data.code === 0) {
                    const respData = res.data.data;
                    const newFile = {...respData, md5};
                    result = newFile;
                } else {
                    message.error('上传失败');
                }
            });
            return result;
        } catch (e) {
            console.info(e)
            message.error(e.message);
            return null;
        } finally {
            setLoading(false);
        }
    }

    // 提供一个自定义的文件项渲染函数
    const itemRender = (originNode, file) => {
        // 为原始节点添加等宽等高样式
        const modifiedOriginNode = React.cloneElement(originNode, {
            style: {
                ...originNode.props.style,
                width: `${cardSize}px`,
                height: `${cardSize}px`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
            }
        });
        
        return (
            <div 
                className="custom-upload-item" 
                style={{ 
                    position: 'relative',
                    width: `${cardSize}px`,
                    height: `${cardSize}px`,
                    margin: '8px',
                }}
            >
                {modifiedOriginNode}
                <div 
                    className="image-action-overlay"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0,
                        transition: 'all 0.2s ease',
                        background: 'rgba(0, 0, 0, 0.45)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.opacity = '1';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.opacity = '0';
                    }}
                >
                    <div style={{ display: 'flex', gap: '16px' }}>
                        {/* View Button */}
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                handlePreview(file);
                            }}
                            style={{
                                width: '36px',
                                height: '36px',
                                borderRadius: '50%',
                                background: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: '#1890ff',
                                fontSize: '18px',
                                transition: 'transform 0.2s',
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.transform = 'scale(1.1)';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                            }}
                        >
                            <Icon.EyeOutlined />
                        </div>
                        
                        {/* Delete Button - Now with animated confirmation */}
                        <div
                            className="delete-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                const deleteButton = e.currentTarget;
                                
                                // Toggle active state for confirmation
                                if (deleteButton.classList.contains('active')) {
                                    // Already active - execute delete
                                    deleteButton.classList.remove('active');
                                    onRemove(file);
                                } else {
                                    // Activate for confirmation
                                    deleteButton.classList.add('active');
                                    deleteButton.innerHTML = '<span>确认</span>';
                                    
                                    // Auto reset after 3 seconds
                                    setTimeout(() => {
                                        if (deleteButton && deleteButton.classList.contains('active')) {
                                            deleteButton.classList.remove('active');
                                            deleteButton.innerHTML = '<span style="font-size: 18px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="delete" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path></svg></span>';
                                        }
                                    }, 3000);
                                }
                            }}
                            style={{
                                width: '36px',
                                height: '36px',
                                borderRadius: '50%',
                                background: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: '#ff4d4f',
                                fontSize: '18px',
                                transition: 'all 0.2s',
                                position: 'relative',
                            }}
                            onMouseEnter={(e) => {
                                if (!e.currentTarget.classList.contains('active')) {
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                }
                            }}
                            onMouseLeave={(e) => {
                                if (!e.currentTarget.classList.contains('active')) {
                                    e.currentTarget.style.transform = 'scale(1)';
                                }
                            }}
                        >
                            <Icon.DeleteOutlined />
                        </div>
                    </div>
                </div>
                <style>{`
                    .delete-button.active {
                        background-color: #ff4d4f !important;
                        color: white !important;
                        transform: scale(1.1);
                    }
                `}</style>
            </div>
        );
    };

    const uploadButton = (
        <div style={{
            height: cardSize,
            width: cardSize,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Icon.PlusOutlined/>
            <div style={{ marginTop: 8, }}>点击上传</div>
        </div>
    );

    return (
        <>
            <Spin spinning={loading}>
                <div style={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    margin: '-8px' 
                }}>
                    <Upload
                        listType="picture-card"
                        fileList={files}
                        onPreview={handlePreview}
                        customRequest={customRequest}
                        onRemove={onRemove}
                        accept={fileType}
                        multiple={multiple}
                        itemRender={itemRender}
                    >
                        {uploadButton}
                    </Upload>
                </div>
                {limit > -1 && (
                    <div style={{ textAlign: 'right', marginTop: 8, fontSize: 12, color: '#888' }}>
                        {files.length}/{limit}
                    </div>
                )}
            </Spin>
            {previewVisible && (
                <div 
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        zIndex: 1000,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        overflow: 'auto'
                    }}
                    onClick={handleCancel}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '16px',
                            right: '16px',
                            color: '#fff',
                            fontSize: '24px',
                            cursor: 'pointer',
                            zIndex: 1001
                        }}
                        onClick={handleCancel}
                    >
                        <Icon.CloseOutlined />
                    </div>
                    <img
                        src={previewImage}
                        alt="预览图片"
                        style={{
                            maxWidth: '90%',
                            maxHeight: '80vh',
                            objectFit: 'contain',
                            cursor: 'default'
                        }}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            )}
        </>
    );
};
UploadFile.propTypes = {
    fileType: PropTypes.string,
    fileLists: PropTypes.instanceOf(Array),
    fileChange: PropTypes.func.isRequired,
    beforeUpload: PropTypes.func, // 上传前校验，默认返回TRUE不校验
    multiple: PropTypes.bool,
    limit: PropTypes.number, // 上传文件数量限制，-1表示不限制
};

UploadFile.defaultProps = {
    fileLists: [],
    fileType: 'image/*',
    beforeUpload: () => true,
    multiple: false,
    limit: -1,
};

export default UploadFile;
